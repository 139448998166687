import { Box, Button } from "@mui/material";
// import UserTable from "../features/users/components/user-table";
import { makeStyles } from "@mui/styles";
// import UserForm from "../features/users/components/user-form";
import { useState } from "react";
import { User } from "../service/model/user.model";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import * as userSlice from "../features/user/user.slice";
import Loader from "../components/loader";
import useUsers from "../hooks/use-users";
import UserTable from "../features/user/components/user-table";
import UserForm from "../features/user/components/user-form";
import { selectLoggedInUser } from "../features/auth/auth.slice";

const useStyles = makeStyles({
    createButton: {
        float: "inline-end",
    }
})

const Users = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const loggedInUser = useAppSelector(selectLoggedInUser);
    const [showUserForm, setShowUserForm] = useState(false);
    const isLoading = useAppSelector(userSlice.selectIsLoading);

    const [users] = useUsers();

    const handleSubmit = (data: User) => {
        console.log("🚀 ~ handleSubmit ~ data:", data)
        loggedInUser && dispatch(userSlice.createUser({ ...data, businessId: loggedInUser.businessId })).then(resp => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setShowUserForm(false);
            }
        });
    };

    return (
        <div>
            {isLoading &&
                <Loader />
            }
            {users &&
                <>
                <Box>
                    <Button variant="contained" className={classes.createButton} onClick={() => setShowUserForm(true)} style={{marginBottom: "12px"}}>Add User</Button>
                </Box>
                <UserTable users={users} />
                {showUserForm &&
                    <UserForm user={undefined} editable={true} onClose={() => setShowUserForm(false)} onSubmit={handleSubmit} />
                }
            </>
            }
        </div>
    )
}

export default Users;