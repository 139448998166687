import { Bar, BarChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import theme from "../../app/app-theme";

export interface DoughnutChartProps {
  data: {name: string, value: number}[],
  label?: string,
  height?: number,
  xAxisAngle?: number,
  xAxisInterval?: number
}


const SingleBarChart: React.FC<DoughnutChartProps> = (props) => {
  return (
    <div>
      <h3 style={{ textAlign: "center", marginTop: 0, marginBottom: 18, height: 28 }}>{props.label}</h3>
      <ResponsiveContainer width={"99%"} height={props.height}>
        <BarChart margin={{ top: 5, right: 5, bottom: 5, left: 5 }} data={props.data} title={props.label}>
          <Tooltip />
          <XAxis dataKey={"name"} angle={props.xAxisAngle ?? 0} dy={10} interval={props.xAxisInterval ?? 0} />
          <YAxis />
          <Bar
            fill={theme.palette.primary.main}
            legendType="line"
            dataKey="value"
            textAnchor="end"
            radius={[4, 4, 0, 0]}
            barSize={18}
          >
            <Label value={props.label} position="center" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default SingleBarChart;