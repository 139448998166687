import { createTheme } from "@mui/material"

const theme = createTheme({
    palette: {
        primary: {
            main:  '#4c4cb0',
            dark: '#4c4cb0',
            light: '#81a2c8',
            contrastText: '#fff'
        },
        secondary: {
            main: '#945c7c',
            dark: '#3C4856',
            light: '#fff',
            contrastText: '#fff'
        },
        error: {
            main: '#ed2f4f',
            dark: '#CB0037',
            light: '#E99F9E',
            contrastText: '#fff'
        },
        warning: {
            main: '#ff9800'
        },
        info: {
            main: '#8ecae6',
            light: '#E5E4E2'
        },
        success: {
            main: '#2CAE66',
            dark: '#3B714E',
            light: '#D4FADE',
            contrastText: '#fff'
        },
        action: {
            hover: '#8ecae6'
        },
        background: {
            default: '#F1F1F1',
        },
        common: {

        }
    },
    typography: {
        fontFamily: 'system-ui'
    }
})
export default theme