import { Typography } from "@mui/material";
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

export interface DoughnutChartProps {
  data: {name: string, value: number}[],
  label?: string
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const DoughnutChart: React.FC<DoughnutChartProps> = ({data, label}) => {
  return (
    <div>
      <h3 style={{ textAlign: "center"}}>{label}</h3>
      <ResponsiveContainer width={"99%"} height={220}>
        <PieChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          <Tooltip />
          <Legend layout="horizontal" verticalAlign="bottom" align="center" />
          <Pie
            data={data}
            innerRadius={"70%"}
            outerRadius={"90%"}
            fill="#8884d8"
            paddingAngle={5}
            legendType="line"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
            {/* <Label value={label} position="center" /> */}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>

  );
}

export default DoughnutChart;