import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getLoggedInUser, resetState, selectLoggedInUser } from "../features/auth/auth.slice";
import React from "react";
import theme from "../app/app-theme";
import { useNavigate } from "react-router";
import UserForm from "../features/user/components/user-form";
import { User } from "../service/model/user.model";
import { updateUser } from "../features/user/user.slice";

const ProfileMenu = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const loggedInUser = useAppSelector(selectLoggedInUser);
    const [showProfile, setShowProfile] = React.useState(false);

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setShowProfile(true);
        setAnchorElUser(null);
    };

    const handleChangePassword = () => {
        setAnchorElUser(null);
        navigate('/change-password');
    };

    const handleLogout = () => {
        localStorage.clear();
        dispatch(resetState());
        navigate('/');
        handleCloseUserMenu();
    }

    const handleSubmit = (data: User) => {
        loggedInUser && dispatch(updateUser({...data, id: loggedInUser.id, businessId: loggedInUser.businessId})).then(resp => {
            if(resp.meta.requestStatus === 'fulfilled') {
                setShowProfile(false);
                dispatch(getLoggedInUser());
            }
        });
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar sx={{ bgcolor: theme.palette.primary.light }}>{loggedInUser?.username[0]?.toUpperCase()}</Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem key={'profile'} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem key={'changePassword'} onClick={handleChangePassword}>
                    <Typography textAlign="center">Change Password</Typography>
                </MenuItem>
                <MenuItem key={'logout'} onClick={handleLogout}>
                    <Typography textAlign="center">Logout</Typography>
                </MenuItem>
            </Menu>
            {showProfile &&
                <UserForm user={loggedInUser} editable={true} onClose={() => setShowProfile(false)} onSubmit={handleSubmit}/>
            }
        </Box>
    );
}

export default ProfileMenu;