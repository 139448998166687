import MemberPaymentIcon from '@mui/icons-material/AttachMoney';
import useMemberPaymentSummary from "../../../hooks/use-member-payment-summary";
import StatCard from "../../../components/StatCard";
import { StatSummary } from "../../../components/StatSummary";
import { Card, Grid, Stack, Typography } from '@mui/material';
import ChartCard from '../../../components/chart/chart-card';
import SingleBarChart from '../../../components/chart/single-bar-chart';
import DoughnutChart from '../../../components/chart/doughnut-chart';
import theme from "../../../app/app-theme";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { CURRENCY } from '../../../shared/constants';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
    //   ...theme.applyStyles('dark', {
    //     backgroundColor: theme.palette.grey[800],
    //   }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
    //   ...theme.applyStyles('dark', {
    //     backgroundColor: '#308fe8',
    //   }),
    },
  }));
  
const MemberPaymentSummary = () => {
    const paymentSummary = useMemberPaymentSummary();

    return (
        <div>
            {paymentSummary &&
                <>
                    <StatSummary>
                        <StatCard title={`Today (${CURRENCY})`} value={paymentSummary?.todayTotal.toLocaleString()} icon={MemberPaymentIcon} />
                        <StatCard title={`This Month (${CURRENCY})`} value={paymentSummary?.currentMonthTotal.toLocaleString()} icon={MemberPaymentIcon} />
                        <StatCard title={`This Year (${CURRENCY})`} value={paymentSummary?.currentYearTotal.toLocaleString()} icon={MemberPaymentIcon} />
                        <StatCard title={`Last Month (${CURRENCY})`} value={paymentSummary?.lastMonthTotal.toLocaleString()} icon={MemberPaymentIcon} />
                    </StatSummary>
                    <Grid container direction="row" justifyContent="flex-start">
                        <Grid item lg={5} md={4} sm={12} xs={12}>
                            <ChartCard><SingleBarChart data={paymentSummary.monthlyTotals.map(item => { return { name: item.key, value: item.value } })} label={`Monthly Income (${CURRENCY})`} height={204} xAxisAngle={-90} /></ChartCard>
                        </Grid>
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                            <ChartCard><DoughnutChart data={paymentSummary.totalByMembership.map(item => { return { name: item.key, value: item.value } })} label='Income by Membership' /></ChartCard>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Card style={{ marginTop: "12px", padding: 12, backgroundColor: theme.palette.primary.contrastText}}>
                            {/* <Card style={{ marginTop: "12px", marginRight: "8px", backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText }}> */}
                                {/* <CardContent style={{ padding: "10px" }}> */}
                                    <h3>
                                        Top Paid Members (Last 12 Months)
                                    </h3>
                                {/* </CardContent> */}
                            {/* </Card> */}
                            <>
                            {paymentSummary?.topPaidMembers?.map(member =>
                                // <Card key={member.key} style={{ marginTop: "8px", marginRight: "8px", padding: "8px", backgroundColor: theme.palette.info.light }}>
                                //     <Grid container spacing={2}>
                                //         <Grid item xs={9}>
                                //             <Typography variant="body1">{member.key}</Typography>
                                //         </Grid>
                                //         <Grid item xs={3}>
                                //             <Typography variant="body1">{member.value}</Typography>
                                //         </Grid>
                                //         <BorderLinearProgress variant="determinate" value={50} />
                                //     </Grid>
                                // </Card>
                                <Stack spacing={2} sx={{ flexGrow: 1 }} key={member.key}>
                                    <Grid container justifyContent="space-between">
                                        <Typography variant="body1">{member.key}</Typography>
                                        <Typography variant="body1">{member.value.toLocaleString()} {CURRENCY}</Typography>

                                    </Grid>
                                    <BorderLinearProgress variant="determinate" value={(member.value / paymentSummary.topPaidMembers[0].value) * 100} style={{ marginTop: 0, marginBottom: 12 }} />
                                </Stack>
                                )}
                            </>
                            </Card>
                        </Grid>
                    </Grid>
                </>
            }
        </div>
    )
}

export default MemberPaymentSummary;