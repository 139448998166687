import { createStyles, makeStyles } from "@mui/styles";
import { Box, Card, Grid, Theme, Typography } from "@mui/material";
import { FC, createElement } from "react";
import theme from "../app/app-theme";

export interface StatCardProps {
    icon: FC<any>;
    title?: string;
    value?: string | number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            marginRight: "8px",
            marginBottom: "8px",
            backgroundColor: theme.palette.secondary.light,
            '& .MuiTypography-root': {
                margin: 'auto',
                color: theme.palette.primary.dark
            },
            minHeight: 52,
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            '& a': {
                textDecoration: 'none',
                color: 'inherit',
            },
        }
    }
    ))

const StatCard: React.FC<StatCardProps> = ({ icon, title, value }: StatCardProps) => {
    const classes = useStyles();

    return (
        <Grid item lg={3} md={6} sm={12} xs={12}>
            <Card className={classes.card} elevation={3}>
                <Box
                    sx={{
                        position: 'relative',
                        overflow: 'hidden',
                        padding: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        '& .icon': {
                            color: theme.palette.primary.dark,
                        },
                        '&:before': {
                            position: 'absolute',
                            top: '50%',
                            left: 0,
                            display: 'block',
                            content: `''`,
                            height: '150%',
                            aspectRatio: '1',
                            transform: 'translate(-30%, -60%)',
                            borderRadius: '50%',
                            backgroundColor: theme.palette.primary.dark,
                            opacity: 0.15,
                        },
                    }}
                >
                    <Box width="3em" className="icon">
                        {createElement(icon, { fontSize: 'large' })}
                    </Box>
                    <Box textAlign="right">
                        <Typography color="textSecondary">{title}</Typography>
                        <Typography variant="h5" component="h2">
                            {value ?? ' '}
                        </Typography>
                    </Box>
                </Box>
            </Card>
        </Grid>
    );
};

export default StatCard;