import { Box, Button } from "@mui/material";
import useMembers from "../hooks/use-members";
import { makeStyles } from "@mui/styles";
import MemberForm from "../features/members/components/member-form";
import { useState } from "react";
import { Member as MemberModel } from "../service/model/member.model";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import * as memberSlice from "../features/members/member.slice";
import Loader from "../components/loader";
import MemberSummary from "../features/members/components/member-summary";
import MemberTable from "../features/members/components/member-table";
import SearchBox from "../components/search-box";
import { selectLoggedInUser } from "../features/auth/auth.slice";

const useStyles = makeStyles({
    createButton: {
        float: 'inline-end',
        margin: '12px 0 4px 12px'
    }
})

const Members = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const loggedInUser = useAppSelector(selectLoggedInUser);

    const [showMemberForm, setShowMemberForm] = useState(false);
    const isLoading = useAppSelector(memberSlice.selectIsLoading);

    const [searchText, setSearchText] = useState('');

    // const getMembers = async () => {
    //     await dispatch(memberSlice.fetchMembers());
    // }

    // useEffect(() => {
    //     if (members.length === 0) {
    //         getMembers();
    //     }
    // }, []);

    const [members] = useMembers();

    const handleSubmit = (data: MemberModel) => {
        loggedInUser && dispatch(memberSlice.createMember({...data, businessId: loggedInUser.businessId})).then((resp) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setShowMemberForm(false);
            }
        });
    };

    function setName(name: string) {
        setSearchText(name);
    }

    const getFilteredMember = () => {
        if (searchText === '') {
            return members;
        } else {
            return members.filter(item => item.firstName.toLowerCase().includes(searchText.toLowerCase()));
        }
    }

    return (
        <div>
            {isLoading &&
                <Loader />
            }
            {members &&
                <>
                    <MemberSummary />
                    <Box sx={{mt: '12px'}}>
                        <Button variant="contained" className={classes.createButton} onClick={() => setShowMemberForm(true)}>Add Member</Button>
                    </Box>
                    <SearchBox placeholder="Search by name" setSearchValue={setName} />
                    <MemberTable members={getFilteredMember()} />
                    {/* <MemberDataTable/> */}
                    {showMemberForm &&
                        <MemberForm member={undefined} editable={true} onClose={() => setShowMemberForm(false)} onSubmit={handleSubmit} />
                    }
                </>
            }
        </div>
    )
}

export default Members;