import { createStyles, makeStyles } from "@mui/styles";
import { Avatar, CardContent, Grid, List, ListItem, ListItemIcon, ListItemText, Theme, Typography } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import theme from "../app/app-theme";
import { SocialIcon } from "react-social-icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            padding: '1rem',
            textAlign: 'center',
            backgroundColor: `${theme.palette.secondary.light}`,
            color: 'black',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'left'
            }
        },
        copyright: {
            textAlign: 'center'
        },
        contact: {

        },
        logo: {
            maxWidth: '100px',
            paddingLeft: '16px',
            // margin: 'auto',
            [theme.breakpoints.down('sm')]: {
                marginLeft: '0'
            }
        },
        social: {
            marginRight: '16px',
            // float: 'left',
            // color: `${theme.palette.secondary.light}`
        },
        footerTitle: {
            paddingLeft: '16px'
        },
        demo: {
            justify: 'space-around',
            border: ` ${theme.palette.primary.main} 1px solid`,
            borderRadius: '40px',
            fontSize: 'large',
            backgroundColor: ` ${theme.palette.primary.main}`,
            color: `${theme.palette.secondary.light}`,
            padding: '18px 36px',
            '& .MuiTypography-root': {
                textAlign: 'center'
            },
            '&:hover': {
                color: `${theme.palette.primary.main} !important`,
                background: `${theme.palette.secondary.light}`
            }
        }
    })
)

function Footer() {
    const classes = useStyles()

    return <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
        <Grid container direction="row" justifyContent="flex-start">
            <Grid item lg={3} md={3} sm={12} xs={12}>
                <Grid item xs={12} sm={12} md={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                    <Avatar sx={{ width: "48", height: "48", marginRight: "12px" }} src="/favicon.ico"></Avatar>
                    <Typography><h2>Gyminify</h2></Typography>
                </Grid>
                <CardContent>
                    <Typography gutterBottom variant="body2" component="h2" color="inherit" align="justify">
                        Gyminify is an all-in-one solution for managing fitness centers. With detailed analytics, it's easy to improve member management, payment management, attendance tracking, staff management, and more. Gyminify provides accurate and timely information necessary to deliver tailored solutions to your business.
                    </Typography>
                </CardContent>
            </Grid>
            <Grid item lg={2} md={2} sm={12} xs={12} />
            <Grid item lg={2} md={2} sm={12} xs={12}>
                <Typography gutterBottom variant="h6" component="h2" color="inherit" justifyContent="center" >Follow Us</Typography>
                <SocialIcon url="https://www.facebook.com/gyminifyapp" fgColor={theme.palette.secondary.light} className={classes.social} />
                {/*<SocialIcon url="https://youtube.com/gyminify" fgColor={theme.palette.secondary.light} className={classes.social}/>*/}
                {/*<SocialIcon url="https://google.com/gyminify" fgColor={theme.palette.secondary.light} className={classes.social}/>*/}
            </Grid>
            <Grid item lg={2} md={2} sm={12} xs={12} />
            <Grid item lg={3} md={3} sm={12} xs={12}>
                <Typography gutterBottom variant="h6" component="h2" color="inherit" align="justify" className={classes.footerTitle}>Get in Touch</Typography>
                <List>
                    <ListItem>
                        <ListItemIcon><EmailIcon color="primary" /></ListItemIcon>
                        <ListItemText primary="gyminify@gmail.com" />
                    </ListItem>
                    {/*<ListItem>*/}
                    {/*    <ListItemIcon><PhoneIcon color="primary"/></ListItemIcon>*/}
                    {/*    <ListItemText primary="+94 71 234 56 78" />*/}
                    {/*</ListItem>*/}
                </List>
            </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center">
            {/* <Divider style={{ width: '100%', margin: '0.5rem 0', background: `${theme.palette.primary.main}` }} /> */}
            <Grid item className={classes.copyright}>
                Copyright ©{new Date().getFullYear()} <a
                    href="https://gyminifyapp.net" target="_blank">Gyminify</a>. All rights reserved.
                {/*| <a href="https://gyminifyapp.net/terms-of-service">Terms</a> | <a href="https://gyminifyapp.net/privacy-policy">Privacy Policy</a>*/}
            </Grid>
        </Grid>
    </Grid>
}

export default Footer