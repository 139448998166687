import AttendanceIcon from '@mui/icons-material/DirectionsWalk';
import useAttendanceSummary from "../../../hooks/use-attendance-summary";
import StatCard from "../../../components/StatCard";
import { StatSummary } from "../../../components/StatSummary";
import ChartCard from '../../../components/chart/chart-card';
import { Card, Grid, LinearProgress, linearProgressClasses, Stack, Typography } from '@mui/material';
import SingleBarChart from '../../../components/chart/single-bar-chart';
import theme from '../../../app/app-theme';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
    //   ...theme.applyStyles('dark', {
    //     backgroundColor: theme.palette.grey[800],
    //   }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
    //   ...theme.applyStyles('dark', {
    //     backgroundColor: '#308fe8',
    //   }),
    },
  }));

const AttendanceSummary = () => {
    const attendanceSummary = useAttendanceSummary();

    return (
        <div>
            {attendanceSummary &&
                <>
                    <StatSummary>
                        <StatCard title="Today" value={attendanceSummary?.todayCount.toString()} icon={AttendanceIcon} />
                        <StatCard title="This Week" value={attendanceSummary?.currentWeekCount.toString()} icon={AttendanceIcon} />
                        <StatCard title="This Month" value={attendanceSummary?.currentMonthCount.toString()} icon={AttendanceIcon} />
                        <StatCard title="Last Month" value={attendanceSummary?.lastMonthCount.toString()} icon={AttendanceIcon} />
                    </StatSummary>
                    <Grid container direction="row" justifyContent="flex-start">
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <ChartCard>
                                <SingleBarChart 
                                    data={attendanceSummary.dailyAttendanceTrend.map(item => { return { name: item.key, value: item.value } })} 
                                    label='Daily Attendance' 
                                    height={204} 
                                    xAxisInterval={7}
                                />
                            </ChartCard>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <ChartCard>
                                <SingleBarChart 
                                    data={attendanceSummary.averageAttendanceForDayOfWeek.map(item => { return { name: item.key.substring(0, 3), value: item.value } })} 
                                    label='Average Attendance for Day' 
                                    height={204} 
                                />
                            </ChartCard>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Card style={{ marginTop: "12px", padding: 12, backgroundColor: theme.palette.primary.contrastText}}>
                                    {/* <Card style={{ marginTop: "12px", marginRight: "8px", backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText }}>
                                        <CardContent style={{ padding: "10px" }}>
                                            <Typography variant="inherit">
                                                Most Active Members (Last 3 Months)
                                            </Typography>
                                        </CardContent>
                                    </Card> */}
                                    <h3>
                                        Most Active Members (Last 3 Months)
                                    </h3>
                            <>
                                {attendanceSummary?.mostActiveMembers?.map(member =>
                                    // <Card key={member.key} style={{ marginTop: "8px", marginRight: "8px", padding: "8px", backgroundColor: theme.palette.info.light }}>
                                    //     <Grid container spacing={2}>
                                    //         <Grid item xs={9}>
                                    //             <Typography variant="body1">{member.key}</Typography>
                                    //         </Grid>
                                    //         <Grid item xs={3}>
                                    //             <Typography variant="body1">{member.value}</Typography>
                                    //         </Grid>
                                    //     </Grid>
                                    // </Card>
                                    <Stack spacing={2} sx={{ flexGrow: 1 }} key={member.key}>
                                    <Grid container justifyContent="space-between">
                                        <Typography variant="body1">{member.key}</Typography>
                                        <Typography variant="body1">{member.value.toLocaleString()}</Typography>

                                    </Grid>
                                    <BorderLinearProgress variant="determinate" value={(member.value / attendanceSummary.mostActiveMembers[0].value) * 100} style={{ marginTop: 0, marginBottom: 12 }} />
                                </Stack>
                                )}
                            </>
                            </Card>
                        </Grid>
                    </Grid>
                </>
            }
        </div>
    )
}

export default AttendanceSummary;